import { appLoadManifest } from "@app/shared/build/load-manifest";
import { environment } from "./environments/environment";
import { loadEnvironmentVariables } from "@app/shared/build/load-environment";

loadEnvironmentVariables(environment)
  .then(() => appLoadManifest(environment))
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err))
  .then((_) => import("./bootstrap"))
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
