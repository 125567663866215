import { AppEnvironmentTemplate } from "../interface/environment";
import { forOwn, isObject, mergeWith } from "lodash";

/**
 * Loads parial {@link AppEnvironmentTemplate projects/shared/interface/environment.ts}
 * with real placeholders replaced with env variables.
 * And deep merge them into {@link environment projects/shared/interface/environment.ts} const
 *
 * @param environment default environment
 * @returns
 */
export async function loadEnvironmentVariables(
  environment: AppEnvironmentTemplate
) {
  if (!environment.production) {
    return;
  }

  const url = "/assets/environment.json";

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    const json = (await response.json()) as AppEnvironmentTemplate;
    defaultsDeepInverse(environment, json);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error((<Error>error).message);
  }
}

/**
 * Mutates destination object.
 * It works like lodash merge except that it does not assign properties from source
 * of key name that do not exists in destination obbject.
 *
 * E.G.:
 * { foo: { moo: 5 } } - destination
 * { foo: { boo: 1, moo: 2} } - source
 *
 * { foo: { moo: 2 } } - result
 *
 * @param destination
 * @param source
 */
function defaultsDeepInverse(destination, source) {
  forOwn(destination, function (value, key) {
    if (source[key]) {
      if (isObject(value)) {
        defaultsDeepInverse(value, source[key]);
      } else {
        destination[key] = source[key];
      }
    }
  });
}
