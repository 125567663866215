import { loadManifest } from "@angular-architects/module-federation";

export function appLoadManifest(environment) {
  return loadManifest(
    environment.production
      ? `/assets/mf.manifest.prod.${environment.appVersion}.json`
      : "/assets/mf.manifest.json",
    false
  );
}
